import { Component, OnInit } from '@angular/core';
import { PartridgeService } from '../core/partridge.services';
import { Credits } from '../core/objects';

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss'],
})
export class CreditsComponent implements OnInit {
  credits: Credits[];

  constructor(private partridge: PartridgeService) {
    this.credits = this.partridge.getCredits();
  }

  ngOnInit(): void {}
}
