import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { PartridgeService } from '../core/partridge.services';

declare const snap: any;
declare const window: any;

@Component({
  selector: 'app-snap',
  templateUrl: './snap.component.html',
  styleUrls: ['./snap.component.scss'],
})
export class SnapComponent implements OnInit {
  @Inject(DOCUMENT) private document: Document;
  accessToken: string | undefined;
  stickerOpen = false;
  entries: [any];
  constructor(
    private cookies: CookieService,
    private partridge: PartridgeService
  ) {
    this.partridge.getBook().then((result) => {
      this.entries = result;
    });
  }

  async ngOnInit() {
    const cookie = this.cookies.get('Set-Cookie');
    console.log(cookie);
    if (cookie) {
      this.accessToken = cookie;
      await this.loadSdk();
      await this.loadStickerPicker();
    }
  }

  friend() {
    const accessToken = this.accessToken;
    snap.bitmojikit.setFriendExternalId(
      environment.snap.externalId,
      accessToken
    );
    snap.bitmojikit.unsetFriendExternalId();

    // this.toggleStickerPicker();
    // this.toggleStickerPicker();
  }

  loadSdk() {
    // tslint:disable-next-line:only-arrow-functions
    (function (d, s, id) {
      let js: any;
      // tslint:disable-next-line:prefer-const
      let sjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://sdk.snapkit.com/js/v1/login_bitmoji.js';
      sjs.parentNode.insertBefore(js, sjs);
    })(document, 'script', 'bitmojikit-sdk');
  }

  toggleStickerPicker() {
    const bitmojiWebPickerIconClass = 'snap-stickerpicker';
    if (this.stickerOpen) {
      snap.bitmojikit.unmountBitmojiStickerPickerIcons(
        bitmojiWebPickerIconClass
      );
      this.stickerOpen = false;
    } else {
      this.loadStickerPicker();
    }
  }

  async signBook(url: string) {
    await this.partridge.signBook(url);
  }

  async getBook() {
    await this.partridge.getBook();
  }

  loadStickerPicker() {
    const _this = this;
    const accessToken = this.accessToken;
    window.snapKitInit = function () {
      const bitmojiWebPickerIconClass = 'snap-stickerpicker';

      const uiOptions = {
        iconOverride: {
          size: 250,
          style: 'Customizable',
          fillColor: '#C0C0C0',
          locale: 'ja-JP',
        },
        fontFamily: 'Helvetica Neue',
        webpickerPosition: 'bottomMiddle',

        onStickerPickCallback: function onStickerPickCallback(bitmojiImgURL) {
          console.log(bitmojiImgURL);
          _this.signBook(bitmojiImgURL);
        },
      };

      const loginParamsObj = {
        // Override this parameter `handleAuthGrantFlowCallback`
        handleAuthGrantFlowCallback: function handleAuthGrantFlowCallback() {
          // TO START THE OAUTH2.0 AUTHORIZATION
          // GRANT FLOW, POINT THIS CALLBACK TO
          // YOUR APPLICATION’S BACKEND HANDLER
          return;
        },
        clientId: environment.snap.clientId,
        redirectURI: 'http://localhost:4200/snap',
        scopeList: ['user.bitmoji.avatar', 'user.display_name'],
      };

      // mount Bitmoji icon(s)
      snap.bitmojikit.mountBitmojiStickerPickerIcons(
        bitmojiWebPickerIconClass,
        uiOptions,
        loginParamsObj,
        accessToken
      );
      snap.bitmojikit.setFriendExternalId(
        environment.snap.externalId,
        accessToken
      );
    };
    if (this.accessToken) {
      setTimeout(() => {
        this.open();
      }, 2000);
    }
  }

  open() {
    const _element: any = document.querySelectorAll('#stickers > div');
    _element[0].children[0].click();
    this.stickerOpen = true;
  }
}
