<div class="snap-wrapper">
  <h2>Guestbook</h2>
  <div class="snap-book">
    <div class="entry" *ngFor="let entry of entries">
      <img src="{{ entry.bitUrl }}" alt="Bitmoji" />
    </div>
  </div>
  <ng-template *ngIf="accessToken">
    <h2>Click 🔽 To Sign Guestbook</h2>
    <div class="sticker-wrapper">
      <div class="snap-stickerpicker" id="stickers"></div>
    </div>
  </ng-template>
</div>
