import { Component, OnInit } from '@angular/core';
import { PartridgeService } from '../core/partridge.services';
import { SkyResponse } from '../core/objects';
import { DatePipe } from '@angular/common';

interface Plant {
  name: string;
  icon: string;
  position?: string;
}

@Component({
  selector: 'app-garden',
  templateUrl: './garden.component.html',
  styleUrls: ['./garden.component.scss'],
})
export class GardenComponent implements OnInit {
  leftPlants: Plant[] = [
    {
      name: 'Stiletto Rose',
      icon: '/assets/img/garden/NH-red_rose_icon.png',
    },
    {
      name: 'Grandiflora Rose',
      icon: '/assets/img/garden/NH-gold_rose_icon.png',
    },
    {
      name: 'Hybrid Tea Rose',
      icon: '/assets/img/garden/NH-pink_rose_icon.png',
    },
    {
      name: 'Hybrid Tea Rose',
      icon: '/assets/img/garden/NH-white_rose_icon.png',
    },
    {
      name: 'Iceberg Rose',
      icon: '/assets/img/garden/NH-white_rose_icon.png',
      position: 'end',
    },
  ];

  backLeftPlants: Plant[] = [
    {
      name: 'Bougainvillea',
      icon: '/assets/img/garden/NH-pink_rose_icon.png',
    },
    {
      name: 'Grandiflora Rose',
      icon: '/assets/img/garden/NH-gold_rose_icon.png',
    },
  ];

  frontPlants: Plant[] = [
    {
      name: 'Osteospermum',
      icon: '/assets/img/garden/NH-yellow_cosmos-icon.png',
    },
    {
      name: 'Osteospermum',
      icon: '/assets/img/garden/NH-pink_cosmos-icon.png',
    },
    {
      name: 'Osteospermum',
      icon: '/assets/img/garden/NH-white_cosmos-icon.png',
    },
    {
      name: 'Osteospermum',
      icon: '/assets/img/garden/NH-red_cosmos-icon.png',
    },
    {
      name: 'Osteospermum',
      icon: '/assets/img/garden/NH-orange_cosmos-icon.png',
    },
    {
      name: 'Osteospermum',
      icon: '/assets/img/garden/NH-white_cosmos-icon.png',
    },
    {
      name: 'Osteospermum',
      icon: '/assets/img/garden/NH-yellow_cosmos-icon.png',
    },
    {
      name: 'Osteospermum',
      icon: '/assets/img/garden/NH-pink_cosmos-icon.png',
    },
    {
      name: 'Osteospermum',
      icon: '/assets/img/garden/NH-red_cosmos-icon.png',
    },
  ];

  backPlants: Plant[] = [
    {
      name: 'Horsetail',
      icon: '/assets/img/garden/NH-bamboo.png',
    },
    {
      name: 'Horsetail',
      icon: '/assets/img/garden/NH-bamboo.png',
    },
    {
      name: 'Horsetail',
      icon: '/assets/img/garden/NH-bamboo.png',
    },

    {
      name: 'Horsetail',
      icon: '/assets/img/garden/NH-bamboo.png',
    },
    {
      name: 'Horsetail',
      icon: '/assets/img/garden/NH-bamboo.png',
    },
    {
      name: 'Horsetail',
      icon: '/assets/img/garden/NH-bamboo.png',
    },
  ];

  rightPlants: Plant[] = [
    {
      name: 'Succulent',
      icon: '/assets/img/garden/succulent-1.png',
    },
    {
      name: 'Succulent',
      icon: '/assets/img/garden/succulent-2.png',
    },
    {
      name: 'Succulent',
      icon: '/assets/img/garden/succulent-3.png',
    },
    {
      name: 'Succulent',
      icon: '/assets/img/garden/succulent-1.png',
    },
    // {
    //   name: 'Succulent',
    //   icon: '/assets/img/garden/succulent-2.png',
    // },
    {
      name: 'Succulent',
      icon: '/assets/img/garden/succulent-3.png',
    },
  ];

  sidePlants: Plant[] = [
    {
      name: 'Weeds',
      icon: '/assets/img/garden/succulent-1.png',
    },
    {
      name: 'Weeds',
      icon: '/assets/img/garden/succulent-1.png',
    },
    {
      name: 'Weeds',
      icon: '/assets/img/garden/succulent-1.png',
    },
    {
      name: 'Weeds',
      icon: '/assets/img/garden/succulent-1.png',
    },
    {
      name: 'Weeds',
      icon: '/assets/img/garden/succulent-1.png',
    },
    {
      name: 'Weeds',
      icon: '/assets/img/garden/succulent-1.png',
    },
    {
      name: 'Weeds',
      icon: '/assets/img/garden/succulent-1.png',
    },
  ];

  planterTop: Plant[] = [
    {
      name: 'Weeds',
      icon: '/assets/img/garden/succulent-1.png',
    },
  ];
  sky: any;
  dayLength: string;
  forecast: string | undefined;

  constructor(
    private partridge: PartridgeService,
    private datePipe: DatePipe
  ) {}

  async ngOnInit() {
    this.getSunrise();
  }

  async getSunrise() {
    this.sky = await this.partridge.getSky();
    console.log(this.sky);

    const measuredTime = new Date(null);
    measuredTime.setSeconds(this.sky.results.day_length);
    this.dayLength = measuredTime.toISOString().substr(11, 8);
    this.forecast = `Hi! I'm Lily and welcome to my Yard!<br/> Today is ${this.datePipe.transform(
      this.sky.sunrise,
      'EEEE'
    )}, ${this.datePipe.transform(
      this.sky.sunrise,
      'MMMM'
    )} ${this.datePipe.transform(
      this.sky.sunrise,
      'dd'
    )}.<br/>Sunrise 🌅 is at ${this.datePipe.transform(
      this.sky.sunrise,
      'h:mma'
    )} and will ☀️ ${
      this.dayLength.slice(0, 2) +
      '.' +
      // tslint:disable-next-line: radix
      Math.floor(parseInt(this.dayLength.slice(3, 5)) / 0.6)
    } hours.<br/>The solar noon is at ${this.datePipe.transform(
      this.sky.solar_noon,
      'hh:mma'
    )}.<br/>Sunset 🌇 will be at ${this.datePipe.transform(
      this.sky.sunset,
      'h:mm a'
    )}.<br/>Water your 🌹🌻🌷 accordingly.`;
  }
}
