<div class="freelance-wrapper">
  <!-- <h2>Freelance</h2> -->
  <section class="skills">
    <h2>Programming Languages</h2>
    <ul class="tech-stack">
      <li *ngFor="let item of languages; let i = index">
        <!-- add  .gradient-animated-1s or .gradient-animated-2 .gradient-animated-3s depending on i value classs-->
        <span
          [ngClass]="'doticon'"
          [ngStyle]="{
            '--icon': 'url(/assets/img/doticons/16/' + item.dotIcon + '.svg)'
          }"
        >
          <svg-icon
            src="/assets/img/doticons/16/{{ item.dotIcon }}.svg"
          ></svg-icon>
          <canvas [ngClass]="'doticon-' + i + 1"></canvas>
        </span>
        <span class="tech-name">{{ item.name }}</span>
      </li>
    </ul>
    <h4>Dev Stack</h4>
    <ul class="tech-stack">
      <li *ngFor="let item of stack" class="icon-stack-{{ item }}">
        <span class="tech-name">{{ item }}</span>
      </li>
    </ul>
    <h4>Tooling</h4>
    <ul class="tech-stack">
      <li *ngFor="let item of tools" class="icon-stack-{{ item }}">
        <span class="tech-name">{{ item }}</span>
      </li>
    </ul>
    <h4>Commerce Expertise</h4>
    <ul class="tech-stack">
      <li *ngFor="let item of platforms" class="icon-stack-{{ item }}">
        <span class="tech-name">{{ item }}</span>
      </li>
    </ul>
    <h4>Certifications</h4>
    <ul class="tech-stack cert-stack">
      <li
        *ngFor="let cert of certifications"
        class="icon-stack-{{ cert.icon }}"
      >
        <span class="tech-name">{{ cert.name }}</span>
      </li>
    </ul>
  </section>
</div>
