import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Credits, SkyResponse } from './objects';
import { credits } from './data';
import { baseUrl, endpoints } from './constants';
import { environment } from '../../environments/environment';
// import { Book } from '../../../../functions/lib/src/book/book.interface';

declare var Stripe: any;

@Injectable({
  providedIn: 'root',
})
export class PartridgeService {
  x: any;

  constructor(private http: HttpClient) {}

  getCredits(): Credits[] {
    return credits;
  }

  async signBook(_url: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const data = {
      bitUrl: _url,
      bitId: '',
    };
    return this.http
      .post(environment.apiBase + '/books', data, httpOptions)
      .toPromise();
  }

  async getBook(): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .get(environment.apiBase + '/books', httpOptions)
      .toPromise();
  }

  async snapLogin(_payload: any) {
    // console.log('PrintfulService.saveBillboard()');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post(environment.apiBase + '/Snap/Login', _payload, httpOptions)
      .toPromise();
  }

  async getSky(): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return await this.http
      .get(baseUrl + endpoints.sunrise, httpOptions)
      .toPromise();
  }
}
