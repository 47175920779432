import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { provideClientHydration } from '@angular/platform-browser';

import { AngularSvgIconModule } from 'angular-svg-icon';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApplyComponent } from './apply/apply.component';
import { ContactComponent } from './contact/contact.component';
import { PartridgeService } from './core/partridge.services';
import { CreditsComponent } from './credits/credits.component';
import { CvComponent } from './cv/cv.component';
import { ErrorComponent } from './error/error.component';
import { FreelanceComponent } from './freelance/freelance.component';
import { GardenComponent } from './garden/garden.component';
import { HomeComponent } from './home/home.component';
import { MediaComponent } from './media/media.component';
import { PayComponent } from './pay/pay.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ProjectsComponent } from './projects/projects.component';
import { SnapModule } from './snap/snap.module';
import { TermsComponent } from './terms/terms.component';
import { Top8Component } from './top8/top8.component';

// Exotics
// import { CookieModule } from 'ngx-cookie';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BnNgIdleService } from 'bn-ng-idle';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { FitnessComponent } from './fitness/fitness.component';
import { ThreeModule } from './three/three.module';

@NgModule({
  declarations: [
    AppComponent,
    CvComponent,
    ProjectsComponent,
    FreelanceComponent,
    ContactComponent,
    ApplyComponent,
    Top8Component,
    MediaComponent,
    ErrorComponent,
    PrivacyComponent,
    TermsComponent,
    HomeComponent,
    CreditsComponent,
    PayComponent,
    GardenComponent,
    FitnessComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCardModule,
    MatListModule,
    MatStepperModule,
    MatIconModule,
    MatTooltipModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    SnapModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    ThreeModule,
  ],
  providers: [
    PartridgeService,
    BnNgIdleService,
    DatePipe,
    CookieService,
    provideClientHydration(),
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
