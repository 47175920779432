import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnapComponent } from './snap.component';
// import { SnapService } from '../snap.service';

@NgModule({
  declarations: [SnapComponent],
  imports: [CommonModule],
  providers: [],
})
export class SnapModule {}
