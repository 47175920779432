import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-top8',
  templateUrl: './top8.component.html',
  styleUrls: ['./top8.component.scss']
})
export class Top8Component implements OnInit {
  dogs = [
    {
      name: 'Lily',
      photo: 'https://pawwty.com/assets/images/image01.jpg?v31245148625751',
      relation: ''
    },
    {
      name: 'Sadie',
      photo: '/assets/img/sadie.jpg',
      relation: ''
    },
    {
      name: 'Rio',
      photo: 'https://pawwty.com/assets/images/image09.jpg?v31245148625751',
      relation: ''
    },
    {
      name: 'Thor & Shilo',
      photo: 'https://pawwty.com/assets/images/image08.jpg?v31245148625751',
      relation: ''
    },
    {
      name: 'Whinston',
      photo: 'https://pawwty.com/assets/images/image02.jpg?v31245148625751',
      relation: ''
    },
    {
      name: 'Chloe',
      photo: 'https://pawwty.com/assets/images/image04.jpg?v31245148625751',
      relation: ''
    },
    {
      name: 'Jeffrey',
      photo: 'https://pawwty.com/assets/images/image03.jpg?v31245148625751',
      relation: ''
    },
    {
      name: 'Hercules',
      photo: 'https://pawwty.com/assets/images/image12.jpg?v31245148625751',
      relation: ''
    },
    {
      name: 'Jeffrey',
      photo: 'https://pawwty.com/assets/images/image03.jpg?v31245148625751',
      relation: ''
    },
    {
      name: 'Ruby Tuesday',
      photo: 'https://pawwty.com/assets/images/image10.jpg?v31245148625751',
      relation: '',
      online: true
    },
    {
      name: 'Coco',
      photo: 'https://pawwty.com/assets/images/image06.jpg?v31245148625751',
      relation: ''
    },
    {
      name: 'Jasmin',
      photo: 'https://pawwty.com/assets/images/image07.jpg?v31245148625751',
      relation: ''
    },
    {
      name: 'Woody',
      photo: 'https://pawwty.com/assets/images/image13.jpg?v31245148625751',
      relation: ''
    },
    {
      name: 'Margaret',
      photo: 'https://pawwty.com/assets/images/image05.jpg?v31245148625751',
      relation: ''
    }
  ];

  forever = [
    {
      name: 'Toby',
      photo: 'https://pawwty.com/assets/images/image11.jpg?v31245148625751',
      relation: ''
    },
    {
      name: 'Brody',
      photo: 'https://pawwty.com/assets/images/image14.jpg?v31245148625751',
      relation: ''
    }
    // {
    //   name: 'Dolce',
    //   photo: 'https://pawwty.com/assets/images/image15.jpg?v31245148625751',
    //   relation: ''
    // }
  ];

  constructor() {}

  ngOnInit() {}
}
