import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit
} from "@angular/core";

@Component({
  selector: "app-apply",
  templateUrl: "./apply.component.html",
  styleUrls: ["./apply.component.scss"]
})
export class ApplyComponent implements OnInit {
  @ViewChild("company") companyInput: ElementRef;
  @ViewChild("job") jobInput: ElementRef;
  @ViewChild("body2") body2Input: ElementRef;

  company_name: string = "SpaceX";
  job_title: string = "CTO";
  me: string = "Shawn";
  cover_letter = {
    opening: "",
    intro:
      "My name is " +
      this.me +
      " and I just relocated to L.A. looking " +
      this.job_title +
      ". I'm applying because I deeply admire Shopify's mission and people. I love working with smart, and passionate engineers to build great products.",
    body:
      "I'd love to contribute to Shopify since I relate deeply with its core mission. I'm confident about my programming skills since I have worked on many different kinds of projects. One project I worked on is a mailing list that e-mails programming problems to readers. I also have extensive experience working in C, Python, Java, and JavaScript.",
    body2: null,
    thanks: "Looking forward to hearing from you soon!",
    closing: "Regards,"
  };
  constructor() {}
  ngOnInit() {}

  generate() {
    this.company_name = this.companyInput.nativeElement.value;
    this.job_title = this.jobInput.nativeElement.value;
    this.cover_letter.body2 = this.body2Input.nativeElement.value;
  }
}
