<div class="apply-wrapper">
  <h2>Cover Letter Generator:</h2>
  <div class="input">
    <label>Company:</label>
    <input #company placeholder="SpaceX" />
  </div>
  <div class="input">
    <label>Job Title:</label>
    <input #job placeholder="CTO" />
  </div>
  <div class="input">
    <button (click)="generate()" elevation="3">Generate</button>
  </div>
  <div class="input-block">
    <label>Custom Line:</label>
    <textarea #body2 placeholder="" rows="1" maxrows="5"></textarea>
  </div>

  <mat-card class="cover-letter">
    <p>Hey {{ company_name }} team!</p>
    <p>{{ cover_letter.intro }}</p>
    <p>{{ cover_letter.body }}</p>
    <p *ngIf="cover_letter.body2">{{ cover_letter.body2 }}</p>
    <p>{{ cover_letter.thanks }}</p>
    <p>{{ cover_letter.closing }}<br />{{ me }}</p>
  </mat-card>
</div>
