<!-- <h4>Garden</h4> -->
<section class="mt-4">
  <div class="header">
    <!-- <div class="lily"></div> -->
    <h1 class="forecast" *ngIf="forecast">
      <div>Hi! I'm Lily and welcome to my Yard!</div>
      <div>Today is {{ sky.sunrise | date: 'EEEE MMMM dd, yyyys' }}</div>
      <div>
        Sunrise is at {{ sky.sunrise | date: 'hh:mm a' }} (☀️
        {{ dayLength | slice: 0:5 }})
      </div>
      <!-- <div></div> -->
      <div>Solar noon is at {{ sky.solar_noon | date: 'hh:mm a' }}</div>
      <div>Sunset is at {{ sky.sunset | date: 'h:mm a' }}</div>
    </h1>
  </div>

  <!-- <div class="sky" *ngIf="sky">
    <div class="sun">Sunrise: {{ sky.sunrise | date: 'medium' }}</div>
    <div class="solar-noon">
      Solar Noon: {{ sky.solar_noon | date: 'medium' }}
    </div>
    <div class="moon">Sunset: {{ sky.sunset | date: 'medium' }}</div>
    <div class="length">Day Length: {{ dayLength }}</div>
  </div> -->
  <div class="property">
    <div class="fence"></div>
    <div class="yard">
      <div class="fence-left"></div>
      <div class="left-planter pattern-cross-dots-lg">
        <div class="flower" *ngFor="let plant of leftPlants">
          <img
            src="{{ plant.icon }}"
            alt="{{ plant.name }}"
            matTooltip="{{ plant.name }}"
          />
        </div>
      </div>
      <div class="yard-left">
        <div
          class="grass grass-left pattern-cross-dots-lg"
          matTooltip="Grass"
        ></div>
        <div class="back-planter pattern-cross-dots-lg">
          <div class="flower" *ngFor="let plant of backLeftPlants">
            <img
              src="{{ plant.icon }}"
              alt="{{ plant.name }}"
              matTooltip="{{ plant.name }}"
            />
          </div>
        </div>
      </div>
      <div
        class="sidewalk pattern-vertical-stripes-sm"
        matTooltip="Sidewalk"
      ></div>
      <div class="yard-right">
        <div class="dog lily" matTooltip="Me"></div>
        <div class="front-planter pattern-cross-dots-lg">
          <div class="flower" *ngFor="let plant of frontPlants">
            <img
              src="{{ plant.icon }}"
              alt="{{ plant.name }}"
              matTooltip="{{ plant.name }}"
            />
          </div>
        </div>
        <div class="main-yard">
          <div
            class="grass grass-right pattern-cross-dots-lg"
            matTooltip="Grass"
          ></div>
          <div class="right-planter">
            <div class="flower" *ngFor="let plant of rightPlants">
              <img
                src="{{ plant.icon }}"
                alt="{{ plant.name }}"
                matTooltip="{{ plant.name }}"
              />
            </div>
          </div>
        </div>
        <div class="back-planter">
          <div class="flower" *ngFor="let plant of backPlants">
            <img
              src="{{ plant.icon }}"
              alt="{{ plant.name }}"
              matTooltip="{{ plant.name }}"
            />
          </div>
        </div>
      </div>

      <div class="fence-right"></div>
    </div>
    <div class="main">
      <div class="house pattern-diagonal-stripes-md" matTooltip="House"></div>
      <div class="side-yard" matTooltip="Side Yard">
        <div class="planter">
          <div class="flower" *ngFor="let plant of planterTop">
            <img
              src="{{ plant.icon }}"
              alt="{{ plant.name }}"
              matTooltip="{{ plant.name }}"
            />
          </div>
        </div>
        <div class="flower" *ngFor="let plant of sidePlants">
          <img
            src="{{ plant.icon }}"
            alt="{{ plant.name }}"
            matTooltip="{{ plant.name }}"
          />
        </div>
      </div>
      <div class="fence-back-right"></div>
    </div>
  </div>
  <div class="turn-device">
    <i class="fa fa-redo"></i>
    <h4>Rotate Device<br />To Display Garden</h4>
  </div>
</section>
