import gsap from 'gsap';

gsap.ticker.fps(12);

const AMOUNT = 24;
const SIZE = 200 / AMOUNT;

// Define the 64-bit Nintendo color palettes
const palettes = [
  ['#6A5ACD', '#008080', '#B22222', '#FF4500'], // Palette 1
  ['#7FFF00', '#D2691E', '#8A2BE2', '#7FFF00'], // Palette 2
  ['#DC143C', '#00FFFF', '#00008B', '#008B8B'], // Palette 3
  ['#B8860B', '#A9A9A9', '#006400', '#BDB76B'], // Palette 4
];

class GlitterBoard {
  private EL: HTMLElement;
  private CANVAS: HTMLCanvasElement;
  private CONTEXT: CanvasRenderingContext2D;
  private HUE: string | undefined;
  private boundGlitter: () => void;

  constructor(el: HTMLElement) {
    this.EL = el;

    this.CANVAS = el.querySelector('canvas') as HTMLCanvasElement;
    this.CONTEXT = this.CANVAS.getContext('2d') as CanvasRenderingContext2D;
    this.HUE = el.dataset?.hue;
    this.init();
  }

  private glitter(): void {
    const selectedPalette =
      palettes[Math.floor(Math.random() * palettes.length)];

    this.CONTEXT.clearRect(0, 0, this.CANVAS.width, this.CANVAS.width);
    for (let i = 0; i < Math.pow(AMOUNT, 2); i++) {
      let x = (i % AMOUNT) * SIZE;
      let y = Math.floor(i / AMOUNT) * SIZE;

      const color =
        selectedPalette[Math.floor(Math.random() * selectedPalette.length)];

      this.CONTEXT.fillStyle = color;
      this.CONTEXT.fillRect(x, y, SIZE, SIZE);
    }
  }

  private init(): void {
    this.CANVAS.width = this.CANVAS.height = this.CANVAS.offsetWidth;
    this.boundGlitter = this.glitter.bind(this);

    // Enable and disable glitter on hover
    // this.EL.addEventListener('pointerenter', this.enable.bind(this));
    // this.EL.addEventListener('pointerleave', this.disable.bind(this));
    this.enable();
  }

  private enable(): void {
    gsap.ticker.add(this.boundGlitter);
  }

  private disable(): void {
    gsap.ticker.remove(this.boundGlitter);
  }
}

export default GlitterBoard;
