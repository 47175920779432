<div class="fitness-wrapper">
  <iframe
    height="160"
    width="300"
    frameborder="0"
    allowtransparency="true"
    scrolling="no"
    src="https://www.strava.com/athletes/54849495/activity-summary/307dfff66113910a8596e18511331fcecce9f044"
  ></iframe>
  <iframe
    height="454"
    width="300"
    frameborder="0"
    allowtransparency="true"
    scrolling="no"
    src="https://www.strava.com/athletes/54849495/latest-rides/307dfff66113910a8596e18511331fcecce9f044"
  ></iframe>
</div>
