import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  @Inject(DOCUMENT) private document: Document;

  constructor(titleService: Title) {
    titleService.setTitle('Shawn_Partridge/Contact.html');
  }

  ngAfterViewInit(): void {
    var el = document.getElementById('typeform');
    var t = 'https://shawn18.typeform.com/to/Bqccop';

    var qs,
      js,
      q,
      s,
      d = document,
      gi = d.getElementById,
      ce = d.createElement,
      gt = d.getElementsByTagName,
      id = 'typef_orm_share',
      b = 'https://embed.typeform.com/';
    if (!gi.call(d, id)) {
      js = ce.call(d, 'script');
      js.id = id;
      js.src = b + 'embed.js';
      q = gt.call(d, 'script')[0];
      q.parentNode.insertBefore(js, q);
    }
  }

  ngOnInit() {
    // (function() {
    //   var qs,
    //     js,
    //     q,
    //     s,
    //     d = document,
    //     gi = d.getElementById,
    //     ce = d.createElement,
    //     gt = d.getElementsByTagName,
    //     id = "typeform",
    //     b = "https://embed.typeform.com/";
    //   if (!gi.call(d, id)) {
    //     js = ce.call(d, "script");
    //     js.id = id;
    //     js.src = b + "embed.js";
    //     q = gt.call(d, "script")[0];
    //     q.parentNode.insertBefore(js, q);
    //   }
    // })();
  }
}
