import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import GlitterBoard from '../GlitterBoard';

@Component({
  selector: 'app-freelance',
  templateUrl: './freelance.component.html',
  styleUrls: ['./freelance.component.scss'],
})
export class FreelanceComponent implements OnInit, AfterViewInit {
  boards: GlitterBoard[] = [];
  languages = [
    {
      name: 'python',
      dotIcon: 'python',
    },
    {
      name: 'typescript',
      dotIcon: 'ts',
    },
    {
      name: 'javascript',
      dotIcon: 'js',
    },
    // {
    //   name: 'solidity',
    //   dotIcon: 'solidity',
    // },
    {
      name: 'C++',
      dotIcon: 'cPlusPlus',
    },
  ];
  stack = [
    'nodejs',
    'angularjs',
    'threejs',
    'react',
    'firebase',
    'rubyonrails',
    'postgresql',
    'mysql',
    'mongodb',
  ];
  platforms = [
    'shopify plus',
    'salesforce commerce',
    'wordpress',
    'drupal',
    'salesforce',
    'squarespace',
  ];
  tools = [
    'photoshop',
    'illustrator',
    'git',
    'airtable',
    'trello',
    'heroku',
    'jira',
    'slack',
    'vscode',
  ];
  certifications = [
    {
      name: 'Generative AI Essentials',
      icon: 'microsoft',
    },
    {
      name: 'Shopify Theme Development',
      icon: 'shopify',
    },
    {
      name: 'Shopify App Development',
      icon: 'shopify',
    },
  ];
  constructor(titleService: Title) {
    titleService.setTitle('Shawn_Partridge/Freelance.html');
  }

  ngOnInit() {}

  ngAfterViewInit() {
    document
      .querySelectorAll('.doticon')
      .forEach((Icon: any) => this.boards.push(new GlitterBoard(Icon)));
  }
}
