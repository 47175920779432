import { Credits } from './objects';
import { MetaDefinition } from '@angular/platform-browser';

// export const meta: MetaDefinition[] = [
//   {
//     name: 'viewport',
//     content:
//       'width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no',
//   },
//   {
//     name: 'keywords',
//     content: this.keywords,
//   },
//   {
//     name: 'description',
//     content: this.description,
//   },
//   { name: 'robots', content: 'index, follow' },
//   { name: 'author', content: 'shawn' },
//   { name: 'twitter:title', content: this.title },
//   { name: 'twitter:card', content: 'summary_large_image' },
//   { name: 'twitter:description', content: this.description },
//   {
//     name: 'twitter:image:src',
//     content: this.twitterImg,
//   },
//   { name: 'og:title', content: this.title },
//   { name: 'og:url', content: 'https://shawn.digital' },
//   { name: 'og:image', content: this.twitterImg },
//   { name: 'og:image:secure_url', content: this.twitterImg },
//   { name: 'og:description', content: this.description },
//   { name: 'og:site_name', content: this.title },
//   { name: 'og:type', content: 'website' },
//   { name: 'snapchat:sticker', content: this.twitterImg },
// ];

export const credits: Credits[] = [
  {
    name: 'Material Design',
    url: 'd',
    author: 'd',
  },
  {
    name: 'After Dark CSS',
    url: 'https://github.com/bryanbraun/after-dark-css',
    author: 'd',
  },
];
