import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
// import { LayerMaterial, Depth, Fresnel } from 'lamina';
import {
  AmbientLight,
  AxesHelper,
  BoxGeometry,
  Clock,
  GridHelper,
  Mesh,
  MeshBasicMaterial,
  PerspectiveCamera,
  Scene,
  WebGLRenderer,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import { DOCUMENT } from '@angular/common';
import { BUTTONS, GamepadWrapper } from 'gamepad-wrapper';
import { ARButton, RealityAccelerator } from 'ratk';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { LDrawLoader } from 'three/examples/jsm/loaders/LDrawLoader';
import { LDrawUtils } from 'three/examples/jsm/utils/LDrawUtils';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory.js';
import { Text } from 'troika-three-text';

// import ShaderRuntime from '@assets/js/shaderfrog-runtime.js';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

@Component({
  selector: 'app-three',
  templateUrl: './three.component.html',
  styleUrls: ['./three.component.scss'],
})
export class ThreeComponent implements OnInit, AfterViewInit {
  @Inject(DOCUMENT) private document: Document;
  element: any;
  ratk: RealityAccelerator;
  controller: any;
  scene = new Scene();
  width = window.innerWidth;
  aspect = window.innerWidth / window.innerHeight;
  camera: PerspectiveCamera;
  clock = new Clock();
  renderer = new WebGLRenderer();
  controls: any;
  windowHalfX = window.innerWidth / 2;
  windowHalfY = window.innerHeight / 2;
  light: any;
  @Input() sceneHeight: number;
  height: any;
  @Input() youtube?: string[];
  bowl: any;
  // shaderfrog = new ShaderRuntime();
  gltfLoader!: GLTFLoader;
  @Input() objectBowl?: boolean;
  lego!: any;
  gui: any;
  guiData = {
    displayLines: true,
    conditionalLines: true,
    smoothNormals: true,
    buildingStep: 0,
    buildingSteps: 0,
    noBuildingSteps: 'No steps.',
    flatColors: false,
    mergeModel: false,
  };
  time: any;
  step = 0;
  recoveredPersistentAnchors = false;
  hitTestTarget: any;

  constructor() {}

  ngOnInit(): void {
    if (!this.sceneHeight) {
      this.sceneHeight = 600;
      this.height = window.innerHeight;
      this.aspect = window.innerWidth / this.height;
    } else {
      this.height = this.sceneHeight;
      this.aspect = window.innerWidth / this.height;
    }
    // console.log(window.innerHeight, this.height);
  }

  ngAfterViewInit() {
    delay(250)
      .then(() => {
        this.init();
      })
      .then(() => {
        this.animate();
      });
    if (true) {
      window.addEventListener('resize', this.resize.bind(this));
    }
  }

  loadHelpers() {
    const axesHelper = new AxesHelper(5);
    this.scene.add(axesHelper);
  }

  init() {
    this.element = document.getElementById('scene');
    // this.gltfLoader = new GLTFLoader();

    // this.scene.background = new Color(0xdeebed);

    // this.scene.fog = new Fog('white', 100, 1000);
    this.light = new AmbientLight(0xffffff, 1);
    this.scene.add(this.light);
    this.camera = new PerspectiveCamera(25, this.aspect, 1, 1000);

    this.camera.position.set(20, 4, -25);
    this.camera.rotation.set(-3.14, 0.68, 3.14);
    this.scene.add(this.camera);

    const gridHelper = new GridHelper(50, 20, 0x888888, 0x444444);
    this.scene.add(gridHelper);

    this.renderer = new WebGLRenderer();
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.width, this.height);
    this.renderer.localClippingEnabled = true;
    this.renderer.xr.enabled = true;

    this.ratk = new RealityAccelerator(this.renderer.xr);
    this.ratk.onPlaneAdded = (plane) => {
      const mesh = plane.planeMesh;
      mesh.material = new MeshBasicMaterial({
        wireframe: true,
        color: Math.random() * 0xffffff,
      });
    };
    this.ratk.onMeshAdded = (mesh) => {
      const meshMesh = mesh.meshMesh;
      meshMesh.material = new MeshBasicMaterial({
        wireframe: true,
        color: Math.random() * 0xffffff,
      });
      meshMesh.geometry.computeBoundingBox();
      console.log(meshMesh.geometry.boundingBox);
      const semanticLabel: Text = new Text();
      meshMesh.add(semanticLabel);
      semanticLabel.text = mesh.semanticLabel;
      semanticLabel.anchorX = 'center';
      semanticLabel.anchorY = 'bottom';
      semanticLabel.fontSize = 0.1;
      semanticLabel.color = 0x000000;
      semanticLabel.sync();
      semanticLabel.position.y = meshMesh.geometry.boundingBox.max.y;
      mesh.userData.semanticLabelMesh = semanticLabel;
    };
    this.scene.add(this.ratk.root);

    this.element.appendChild(this.renderer.domElement);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.screenSpacePanning = true;
    this.controls.minDistance = 3;
    this.controls.maxDistance = 100;
    // this.controls.target.set(0, 0, 0);
    this.controls.update();

    this.loadLego(true);
    this.setupRealityAccelerator();
    // this.loadHelpers();
  }

  loadLego(controls: boolean) {
    console.log('loadLego');
    const lDrawLoader = new LDrawLoader();
    lDrawLoader.smoothNormals = true;

    const ldrawPath = 'assets/ldraw/models/';

    lDrawLoader.smoothNormals = true;

    const filePath = 'assets/ldraw/models/x-wing.mpd';

    lDrawLoader.load(
      filePath,
      (group) => {
        console.log('loadLego', group);
        if (this.lego) {
          this.scene.remove(this.lego);
        }
        this.lego = group;

        // demonstrate how to use convert to flat colors to better mimic the lego instructions look
        if (false) {
          function convertMaterial(material) {
            const newMaterial = new MeshBasicMaterial();
            newMaterial.color.copy(material.color);
            newMaterial.polygonOffset = material.polygonOffset;
            newMaterial.polygonOffsetUnits = material.polygonOffsetUnits;
            newMaterial.polygonOffsetFactor = material.polygonOffsetFactor;
            newMaterial.opacity = material.opacity;
            newMaterial.transparent = material.transparent;
            newMaterial.depthWrite = material.depthWrite;

            return newMaterial;
          }

          this.lego.traverse((c: any) => {
            if (c.isMesh) {
              if (Array.isArray(c.material)) {
                c.material = c.material.map(convertMaterial);
              } else {
                c.material = convertMaterial(c.material);
              }
            }
          });
        }

        // Merge model geometries by material
        if (false) {
          this.lego = LDrawUtils.mergeObject(this.lego);
        }

        // Convert from LDraw coordinates: rotate 180 degrees around OX
        this.lego.scale.x = this.lego.scale.y = this.lego.scale.z = 0.04;
        this.lego.rotation.x = -0.056;
        this.lego.rotation.y = 0.304;
        this.lego.rotation.z = 3.12;
        this.lego.position.y = 1.05;

        // this.lego.visible = 10;

        this.scene.add(this.lego);
        this.guiData.buildingSteps = this.lego.userData.numBuildingSteps - 1;
        // this.updateObjectsVisibility();
        if (true) {
          const orbit = new OrbitControls(
            this.camera,
            this.renderer.domElement
          );
          orbit.update();
          orbit.addEventListener('change', this.render.bind(this));

          const control = new TransformControls(
            this.camera,
            this.renderer.domElement
          );
          control.addEventListener('change', this.render.bind(this));
          control.addEventListener('dragging-changed', (event) => {
            orbit.enabled = !event.value;
          });

          // control.attach(this.lego);
          control.name = 'control';
          control.setMode('rotate');
          // console.log(control);
          // this.scene.add(control);
        }
        // this.initGUI();
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
      },
      (error) => {
        console.error(error);
      }
    );
  }

  animate() {
    if (this.renderer) {
      this.renderer.setAnimationLoop(() => this.render());
    }

    // this.render();
  }

  render() {
    this.time = this.clock.getElapsedTime() * 3;
    // if (this.shaderfrog) {
    //   this.shaderfrog.updateShaders(this.clock.getElapsedTime());
    // }

    if (this.lego && !this.renderer.xr.isPresenting) {
      if (this.time < this.lego.userData.numBuildingSteps) {
        this.step = this.time.toFixed(0);
      } else if (this.time > this.lego.userData.numBuildingSteps) {
        // this.clock.stop();
        // this.clock.start();
        this.controls.autoRotate = true;
        this.controls.update();
        if (this.lego.position.y < 3.2) {
          this.lego.position.y += 0.01;
        } else {
          this.lego.position.y += Math.cos(this.time) * 0.01;
        }
      } else if (this.step === 1) {
        // this.time = 0;
      }

      this.lego.traverse((c) => {
        if (c.isGroup) {
          c.visible = c.userData.buildingStep < this.step;
        }
      });
    }

    if (this.renderer.xr.isPresenting && !this.recoveredPersistentAnchors) {
      setTimeout(() => {
        this.ratk.restorePersistentAnchors().then(() => {
          this.ratk.anchors.forEach((anchor) => {
            console.log(anchor);
            const geometry = new BoxGeometry(0.05, 0.05, 0.05);
            const material = new MeshBasicMaterial({ color: 0x0ffff0 });
            const cube = new Mesh(geometry, material);
            anchor.add(cube);
          });
        });
      }, 1000);

      this.recoveredPersistentAnchors = true;
    }

    this.updateController(this.controller);

    if (this.ratk) {
      this.ratk.update();
    }

    this.renderer.render(this.scene, this.camera);
  }

  resize() {
    console.log('resize');
    this.windowHalfX = window.innerWidth / 2;
    this.windowHalfY = window.innerHeight / 2;
    if (this.renderer.xr.isPresenting) {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    } else {
      this.camera.aspect = window.innerWidth / this.sceneHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, this.sceneHeight);
    }
  }

  updateController(controller) {
    if (controller.gamepadWrapper && controller.hitTestTarget) {
      controller.gamepadWrapper.update();
      if (
        controller.gamepadWrapper.getButtonClick(BUTTONS.XR_STANDARD.TRIGGER)
      ) {
        // RATK code
        this.ratk
          .createAnchor(
            controller.hitTestTarget.position,
            controller.hitTestTarget.quaternion,
            true
          )
          .then((anchor) => {
            const geometry = new BoxGeometry(0.05, 0.05, 0.05);
            const material = new MeshBasicMaterial({ color: 0x00ff00 });
            const cube = new Mesh(geometry, material);
            anchor.add(cube);
            console.log(anchor.isPersistent, anchor.anchorID);
          });
      }
      if (
        controller.gamepadWrapper.getButtonClick(BUTTONS.XR_STANDARD.SQUEEZE)
      ) {
        this.ratk.anchors.forEach((anchor) => {
          console.log(anchor.anchorID);
          this.ratk.deleteAnchor(anchor);
        });
      }
    }
  }

  setupRealityAccelerator() {
    const arButton = document.getElementById('ar-button') as HTMLButtonElement;
    const webLaunchButton = document.getElementById('web-launch-button');
    webLaunchButton.onclick = () => {
      window.open(
        'https://www.oculus.com/open_url/?url=' +
          encodeURIComponent(window.location.href)
      );
    };

    ARButton.convertToARButton(arButton, this.renderer, {
      requiredFeatures: [
        'anchors',
        'plane-detection',
        'hit-test',
        'mesh-detection',
        'local-floor',
      ],
      onUnsupported: () => {
        arButton.style.display = 'none';
        webLaunchButton.style.display = 'block';
      },
      onSessionStarted: () => {
        arButton.style.display = 'none';
        webLaunchButton.style.display = 'none';
        this.resize();
      },
    });

    this.controller = this.renderer.xr.getController(0);
    this.controller.addEventListener('connected', async function (event) {
      this.gamepadWrapper = new GamepadWrapper(event.data.gamepad);
      this.hitTestTarget =
        await this.ratk.createHitTestTargetFromControllerSpace(
          event.data.handedness
        );
    });
    this.controller.addEventListener('disconnected', function () {
      this.remove(this.children[0]);
      this.gamepadWrapper = null;
    });
    this.scene.add(this.controller);

    const controllerModelFactory = new XRControllerModelFactory();

    const controllerGrip = this.renderer.xr.getControllerGrip(0);
    controllerGrip.add(
      controllerModelFactory.createControllerModel(controllerGrip)
    );
    this.scene.add(controllerGrip);
  }

  lamina() {
    // const material = new LayerMaterial({
    //   // color: new Color("blue").convertSRGBToLinear(),
    //   alpha: 0.3,
    //   layers: [
    //     new Depth({
    //       far: 4,
    //       origin: [1, 0, 1],
    //       colorA: new Color("grey").convertSRGBToLinear(),
    //       colorB: new Color("white").convertSRGBToLinear(),
    //       alpha: 0.3,
    //       mode: "multiply",
    //       mapping: "vector",
    //     }),
    //     new Fresnel({
    //       mode: "reflect",
    //       color: "blue",
    //       alpha: 0.4,
    //     }),
    //   ],
    // });
  }

  initGUI() {
    if (this.gui) {
      this.gui.destroy();
    }

    this.gui = new GUI();

    this.gui
      .add(this.guiData, 'flatColors')
      .name('Flat Colors')
      .onChange(() => {
        // reloadObject(false);
      });

    this.gui
      .add(this.guiData, 'mergeModel')
      .name('Merge model')
      .onChange(() => {
        // reloadObject(false);
      });

    if (this.lego.userData.numConstructionSteps > 1) {
      this.gui
        .add(
          this.guiData,
          'constructionStep',
          0,
          this.lego.userData.numConstructionSteps - 1
        )
        .step(1)
        .name('Construction step')
        .onChange(this.updateObjectsVisibility.bind(this));
    } else {
      this.gui
        .add(this.guiData, 'noConstructionSteps')
        .name('Construction step')
        .onChange(this.updateObjectsVisibility.bind(this));
    }

    this.gui
      .add(this.guiData, 'smoothNormals')
      .name('Smooth Normals')
      .onChange(function changeNormals() {
        // this.loadLego(false);
      });

    this.gui.add(this.guiData, 'displayLines').name('Display Lines');
    // .onChange(updateObjectsVisibility);
    this.gui.add(this.guiData, 'conditionalLines').name('Conditional Lines');
    // .onChange(this.loadLego(false));
  }

  updateObjectsVisibility() {
    if (this.lego) {
      this.lego.traverse((c) => {
        if (c.isLineSegments) {
          if (c.isConditionalLine) {
            c.visible = this.guiData.conditionalLines;
          } else {
            c.visible = this.guiData.displayLines;
          }
        } else if (c.isGroup) {
          c.visible = c.userData.buildingStep <= this.guiData.buildingStep;
        }
      });
    }
  }
}
