<div
  class="body-wrapper"
  #bodyBg
  [class.dark-mode-bg]="darkMode"
  *ngIf="!screenSaver"
></div>
<div class="app-wrapper" [class.dark-mode]="darkMode" *ngIf="!screenSaver">
  <header>
    <!-- <div class="svg-bg" id="topScene">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 150">
        <path
          fill="#0099ff"
          fill-opacity="1"
          d="M0,64L80,58.7C160,53,320,43,480,48C640,53,800,75,960,74.7C1120,75,1280,53,1360,42.7L1440,32L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
        ></path>
      </svg>
    </div> -->
    <div class="intro-wrapper">
      <!-- <div class="snap-wrapper">
      <a mat-raised-button class="auth-cta auth-snap" href="{{ snapRoute }}"
        ><img src="/assets/img/ghost.png" class="ghost" />Sign Guestbook</a
      >
    </div> -->
      <app-three [sceneHeight]="600" class="three"></app-three>
      <div class="shawn-name">
        <div class="bitmoji animated fadeIn" #bitMojiWrapper>
          <img
            src="{{ currentBitmoji }}"
            alt="It's me Shawn"
            class="img-responsive animated fadeIn"
            (click)="root()"
            #bitMoji
          />
        </div>
        <h1 style="display: none">Shawn Partridge</h1>
        <img src="/assets/img/shawn1.gif" alt="Shawn Partridge" />
        <h4 class="headline">
          {{ summary }}
        </h4>
      </div>
    </div>

    <div class="container" id="wrapper">
      <div class="cta-wrapper">
        <!-- <h4 class="headline">
          {{ summary }}
        </h4> -->

        <div class="nav">
          <button
            mat-raised-button
            color="basic"
            class="btn-grad-4"
            id="cvBtn"
            routerLink="/cv"
            (click)="navClick('cv')"
          >
            CV
          </button>
          <button
            mat-raised-button
            color="basic"
            class="btn-grad-4"
            id="projectsBtn"
            routerLink="/projects"
            (click)="navClick('projects')"
          >
            Projects
          </button>
          <button
            mat-raised-button
            color="basic"
            id="freelanceBtn"
            class="btn-grad-4"
            routerLink="/freelance"
            (click)="navClick('freelance')"
          >
            Freelance
          </button>
          <button
            mat-raised-button
            color="basic"
            class="btn-grad-4"
            id="hireBtn"
            routerLink="/contact"
            (click)="navClick('contact')"
          >
            Contact
          </button>
        </div>
      </div>
    </div>
  </header>

  <div class="container">
    <router-outlet></router-outlet>
  </div>

  <footer>
    <div class="bitmoji animated fadeIn">
      <img
        src="/assets/img/bit/summer-bean.png"
        alt="Bye"
        class="img-responsive animated fadeIn"
        (click)="root()"
        #bitMoji
      />
    </div>
    <div class="sub-footer">
      <div class="git-wrapper">
        <div class="git">
          <!-- <h1 (click)="contact()">Get In Touch</h1> -->
          <div class="social-link-wrapper">
            <div class="social-link" *ngFor="let link of links">
              <a href="{{ link.url }}" alt="{{ link.icon }}"
                ><i class="fab fa-{{ link.icon }}"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        class="footer-svg"
      >
        <path
          fill="#0099ff"
          fill-opacity="1"
          d="M0,160L40,138.7C80,117,160,75,240,80C320,85,400,139,480,181.3C560,224,640,256,720,256C800,256,880,224,960,229.3C1040,235,1120,277,1200,282.7C1280,288,1360,256,1400,240L1440,224L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
        ></path>
      </svg>
    </div>
  </footer>

  <div class="dark-mode-container">
    <i id="darkMode" class="far fa-moon" (click)="toggleDark()"></i>
  </div>
</div>
<div class="screen-saver" *ngIf="screenSaver">
  <!-- Time -->
  <div class="clock">
    <div class="day">{{ time | date: 'EEEE, MMMM d' }}</div>
    <div class="time">
      <span>{{ time | date: 'h:mm:ss' }}</span>
      <span style="float: right">{{ time | date: 'a' }}</span>
    </div>
  </div>
  <!-- First group of objects -->
  <div class="toaster t1 p6"></div>
  <div class="toaster t3 p7"></div>
  <div class="toast tst1 p8"></div>
  <div class="toaster t3 p9"></div>
  <div class="toaster t1 p11"></div>
  <div class="toaster t3 p12"></div>
  <div class="toaster t2 p13"></div>
  <div class="toast tst3 p14"></div>
  <div class="toast tst2 p16"></div>
  <div class="toaster t1 p17"></div>
  <div class="toast tst2 p19"></div>
  <div class="toast tst3 p20"></div>
  <div class="toaster t2 p21"></div>
  <div class="toast tst1 p24"></div>
  <div class="toaster t1 p22"></div>
  <div class="toast tst2 p26"></div>
  <div class="toaster t1 p28"></div>
  <div class="toast tst2 p30"></div>
  <div class="toaster t2 p31"></div>
  <div class="toaster t1 p32"></div>
  <div class="toast tst3 p33"></div>

  <!-- wave 1 of (fast delayed) objects -->
  <div class="toaster t4 p27"></div>
  <div class="toaster t4 p10"></div>
  <div class="toaster t4 p25"></div>
  <div class="toaster t4 p29"></div>

  <!-- wave 2 of (delayed) objects -->
  <div class="toaster t5 p15"></div>
  <div class="toaster t5 p18"></div>
  <div class="toaster t5 p22"></div>

  <!-- wave 3 of (delayed) objects -->
  <div class="toaster t6 p6"></div>
  <div class="toaster t6 p11"></div>
  <div class="toaster t6 p15"></div>
  <div class="toaster t6 p19"></div>
  <div class="toaster t6 p23"></div>

  <!-- wave 5 of (delayed) objects -->
  <div class="toast tst4 p10"></div>
  <div class="toast tst4 p23"></div>
  <div class="toast tst4 p15"></div>
  <div class="toaster t7 p7"></div>
  <div class="toaster t7 p12"></div>
  <div class="toaster t7 p16"></div>
  <div class="toaster t7 p20"></div>
  <div class="toaster t7 p24"></div>

  <!-- wave 6 of (delayed) objects -->
  <div class="toaster t8 p8"></div>
  <div class="toaster t8 p13"></div>
  <div class="toaster t8 p17"></div>
  <div class="toaster t8 p25"></div>

  <!-- wave 7 of (delayed) objects -->
  <div class="toaster t9 p14"></div>
  <div class="toaster t9 p18"></div>
  <div class="toaster t9 p21"></div>
  <div class="toaster t9 p26"></div>
</div>
