import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CvComponent } from './cv/cv.component';
import { ProjectsComponent } from './projects/projects.component';
import { FreelanceComponent } from './freelance/freelance.component';
import { ContactComponent } from './contact/contact.component';
import { ApplyComponent } from './apply/apply.component';
import { Top8Component } from './top8/top8.component';
import { MediaComponent } from './media/media.component';
import { ErrorComponent } from './error/error.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { HomeComponent } from './home/home.component';
import { CreditsComponent } from './credits/credits.component';
import { GardenComponent } from './garden/garden.component';
import { SnapComponent } from './snap/snap.component';
import { FitnessComponent } from './fitness/fitness.component';

const routes: Routes = [
  { path: '', redirectTo: '/freelance', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'cv', component: CvComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'freelance', component: FreelanceComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'apply', component: ApplyComponent },
  { path: 'top8', component: Top8Component },
  { path: 'media', component: MediaComponent },
  { path: 'garden', component: GardenComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'credits', component: CreditsComponent },
  { path: 'snap', component: SnapComponent },
  { path: 'fitness', component: FitnessComponent },
  { path: '**', component: ErrorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
