<div class="top-wrapper">
  <h2>Top 8:</h2>
  <div class="dogs-wrapper">
    <div class="dog" *ngFor="let dog of dogs | slice: 0:8">
      <img src="{{ dog.photo }}" alt="{{ dog.name }}" />
      <div class="dog-name">{{ dog.name }}</div>
      <span class="online" *ngIf="dog.online"
        ><img src="/assets/img/online-now.gif"
      /></span>
    </div>
  </div>

  <h2>Awwnorable Mentions:</h2>
  <div class="dogs-wrapper">
    <div class="dog" *ngFor="let dog of dogs | slice: 9:100">
      <img src="{{ dog.photo }}" alt="{{ dog.name }}" />
      <div class="dog-name">{{ dog.name }}</div>
      <span class="online" *ngIf="dog.online"
        ><img src="/assets/img/online-now.gif"
      /></span>
    </div>
  </div>
  <h2>Forever:</h2>
  <div class="dogs-wrapper">
    <div class="dog" *ngFor="let dog of forever">
      <img src="{{ dog.photo }}" alt="{{ dog.name }}" />
      <div class="dog-name">{{ dog.name }}</div>
    </div>
  </div>
</div>
