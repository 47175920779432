import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
  isDevMode,
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', './screen-saver.css'],
})
export class AppComponent implements OnInit {
  darkMode = false;
  screenSaver = false;
  time = new Date();
  bitCounter = 0;
  currentBitmoji: string;
  bitmojiSelect: any = {
    dark: 'assets/img/beard-more.png',
    light: 'assets/img/bit-2020-intense.jpeg',
    default: 'assets/img/beard-hi.png',
    freelance: 'assets/img/bit-long-dog.png',
    top8: 'assets/img/bit-2020-dog-2.png',
    projects: 'assets/img/beard-goodat.png',
    cv: 'assets/img/beard-job.png',
    contact: 'assets/img/beard-good.png',
    media: 'assets/img/bit-2020-wtf.png',
    error: 'assets/img/bit-2020-wtf.png',
    sleep: 'assets/img/bit-sleep.png',
    meditation: 'assets/img/beard-meditation.png',
    covid: {
      wash: 'assets/img/bit/cv-wash.png',
      distance: 'assets/img/bit/cv-distance.png',
      home: 'assets/img/bit/cv-home.png',
      wfh: 'assets/img/bit/cv-wfh.png',
    },
  };
  title = 'Shawn_Partridge.html';
  description =
    'Portfolio and Homepage of Shawn Partridge. Los Angeles Based Remote Engineer.';
  keywords =
    'shawn partridge, web design, developer, fullstack, partridge, angular, bitmoji, redondo beach, ';
  twitterImg = 'https://shawn.digital/assets/img/beard-more.png';
  summary = 'Los Angeles Based Remote Engineer.';

  links = [
    {
      icon: 'linkedin-in',
      url: 'https://www.linkedin.com/in/shaaaaawn/',
    },
    {
      icon: 'github',
      url: 'https://github.com/shaaaaawn',
    },
  ];

  snapRoute = environment.apiBase + '/auth/snapchat';

  @ViewChild('bitMoji') myBitmoji: ElementRef;
  @ViewChild('bodyBg') bodyBg: ElementRef;
  @ViewChild('bitMojiWrapper') bitWrapper: ElementRef;

  @HostListener('mousemove') onMouseMove() {
    if (this.screenSaver) {
      this.toggleScreenSaver();
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent): void {
    if (this.screenSaver) {
      this.toggleScreenSaver();
    }
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: FocusEvent): void {
    if (!this.screenSaver && !isDevMode) {
      this.toggleScreenSaver();
    }
  }

  @HostListener('touchstart', ['$event'])
  onTouch(event: FocusEvent): void {
    if (this.screenSaver) {
      this.toggleScreenSaver();
    }
  }

  @Inject(DOCUMENT) private document: Document;

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    titleService: Title,
    metaService: Meta,
    private idleService: BnNgIdleService
  ) {
    // this.route.url.subscribe((results) => {
    //   console.log(results);
    // });
    titleService.setTitle(this.title);
    metaService.addTags([
      {
        name: 'viewport',
        content:
          'width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no',
      },
      {
        name: 'keywords',
        content: this.keywords,
      },
      {
        name: 'description',
        content: this.description,
      },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'shawn' },
      { name: 'twitter:title', content: this.title },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:description', content: this.description },
      {
        name: 'twitter:image:src',
        content: this.twitterImg,
      },
      { name: 'og:title', content: this.title },
      { name: 'og:url', content: 'https://shawn.digital' },
      { name: 'og:image', content: this.twitterImg },
      { name: 'og:image:secure_url', content: this.twitterImg },
      { name: 'og:description', content: this.description },
      { name: 'og:site_name', content: this.title },
      { name: 'og:type', content: 'website' },
      { name: 'snapchat:sticker', content: this.twitterImg },
    ]);
    this.currentBitmoji = this.bitmojiSelect.covid.wfh;
  }

  ngOnInit() {
    this.idleScreenSaver();
    const darkPreferred = window.matchMedia(
      '(prefers-color-scheme: dark)'
    ).matches;
    if (darkPreferred) {
      this.toggleDark();
    }
  }

  idleScreenSaver() {
    this.idleService.startWatching(60).subscribe((res: any) => {
      if (res && !this.screenSaver) {
        this.toggleScreenSaver();
      }
    });
    setInterval(() => {
      this.time = new Date();
    }, 1000);
  }

  toggleDark() {
    this.darkMode = !this.darkMode;
    if (!this.myBitmoji) {
      return;
    }
    if (this.darkMode) {
      this.myBitmoji.nativeElement.src = this.bitmojiSelect.dark;
    } else {
      this.myBitmoji.nativeElement.src = this.bitmojiSelect.light;
    }
  }

  navClick(route: string) {
    // this.router.navigate([route]);
    this.myBitmoji.nativeElement.src = this.bitmojiSelect[route];
  }

  root() {
    this.router.navigate(['/']);
  }

  toggleScreenSaver() {
    const body = document.body;
    if (!this.screenSaver) {
      this.currentBitmoji = this.bitmojiSelect.sleep;
      setTimeout(() => {
        body.classList.add('screen-saver');
        this.screenSaver = !this.screenSaver;
      }, 500);
    } else {
      this.currentBitmoji = this.bitmojiSelect.meditation;
      body.classList.remove('screen-saver');
      this.screenSaver = !this.screenSaver;
    }
    this.idleService.resetTimer();
  }
}
