import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-cv',
  templateUrl: './cv.component.html',
  styleUrls: ['./cv.component.scss'],
})
export class CvComponent implements OnInit, AfterViewInit {
  @Inject(DOCUMENT) private document: Document;
  experience = [
    {
      company: 'Equinox Media',
      website: 'https://equinoxplus.com',
      ig: 'eqxplus',
      bitmoji: 'bit-long-dog.png',
      type: 'connected fitness',
      location: 'Los Angeles, CA',
      time: '2020 - 2022',
      title: 'Director of Engineering, Business Systems',
      expanded: true,
      summary:
        'Engineering Manager overseeing the Web Platform and D2C Systems that power the SoulCycle At-Home Bike and the Equinox+ Connected Fitness Platform at Equinox Media.',
      detailSummary: 'Tech Portfolio Includes:',
      detail: [
        'Web Engineering (React, Node, AWS)',
        'eCommerce (Shopify Plus, Payment Gateways)',
        'Logistics (3PL, Last-Mile, Drop-shipping, EDI, Reverse Logistics)',
        'Membership (Subscriptions , CRM, CX)',
        'ERP (Netsuite), Integrations and Middleware',
      ],
      logo: 'assets/img/equinoxpluslogo.png',
      youtube: 'https://www.youtube.com/embed/53EeSd2sEgA',
      // banner: '',
    },
    // {
    //   company: 'Puppies Make Me Happy',
    //   website: 'https://puppiesmakemehappy.com',
    //   ig: 'puppiesmakemehappy',
    //   bitmoji: 'bit-long-dog.png',
    //   type: 'brand',
    //   location: 'Los Angeles, CA',
    //   time: '2015 - 2019',
    //   title: 'eCommerce Manager / Digital Designer',
    //   expanded: false,
    //   summary:
    //     'Freelance developer turned in-house developer for D2C apparel brand making activewear and high fashion for dog lovers. I was responsible for the development of digital channels that helped grow the brand to Millions in revenue and featured at Soul Cycle, Petco & Microsoft.',
    //   detail: [
    //     'Developed Shopify storefronts and configure Shopify Apps / API to serve business & marketing needs.',
    //     'Implementation and customization of 3rd party software for email marketing, inventory management, and shipping.',
    //     'Engineering of a custom Web Application to support the creation of a new Your Dog Here product line using Angular / Firebase / Zapier. ',
    //     'Create custom Shopify Liquid Templates with AngularJS and Firebase to enable on-site dog voting contests.',
    //     'Development of iOS Sticker Packs / Giphy Channel / Instagram / Snap generating 800MM+ views. ',
    //     'Developed and Executed Paid Ad Strategy on Facebook, Instagram, Google, Adroll, Snap.',
    //     'Helped grow social channels to 100k+ followers by auto-generating dog memes from trending Reddit feeds using Python.',
    //     'Planning and execution of pop-ups at Nordstroms, Dog Events, Yoga Events, and Music Festivals.',
    //     'Digital product development with designers and brand partners using Adobe Creative Suite.',
    //     'Partnerships with Influencers and Animal Rescues to donate $100k+ to animal rescues nationwide.',
    //   ],
    //   logo: 'https://cdn.shopify.com/s/files/1/1093/8038/files/t-ball-80-2.gif?v=1566927003',
    //   banner: '/assets/img/puppies-1.jpg',
    // },
    {
      company: 'Henri Home',
      website: 'https://henrihome.com',
      ig: 'henrihome',
      bitmoji: 'bit-long-a.png',
      type: 'startup',
      location: 'Scottsdale, AZ',
      time: '2015 - 2018',
      title: 'Chief Technology Officer',
      expanded: true,
      summary:
        'Technical Lead for Real Estate SaaS startup building resident portal software for Apartment Communities.',
      detail: [
        'Work closely with CEO to determine product direction, roadmap, and next steps.',
        'Transitioned Engineering from out-sourced dev shop to in-house 5 person Ruby on Rails dev team.',
        'Implemented Kanban Methodology into the SDLC with Trello.',
        'Managed API setup & keys with Integration Partners including Yardi, PayYourRent.com, Resman, Paysafe, Google.',
        'Architected features: Rent Payment, Maintenance Requests, Packages, Amenities, Chat, Wall, Marketplace, Fitness Classes, and Dating. ',
        'Individually developed iOS and Android apps with AngularJS / Ionic.',
        'Develop DevOps routines & procedures overseeing Heroku, AWS, Cloudflare, CI/CD.',
        'Launched product and onboarded property management client with 45 apartment communities / 12,000 residents.',
        'Sales engineer in sales demos and technical point of contact with clients.',
        'Perform Quality Assurance of application and develop routines for async bug management.',
        'Create job descriptions, source quality candidates, hire and mentor engineers.',
      ],
      logo: '/assets/img/henri-logo.jpg',
      // banner: '/assets/img/henri-features.png',
      youtube: 'https://www.youtube.com/embed/qSvoSlx-_UE',
    },
    {
      company: 'Bank of America',
      status: 'Active',
      website: 'https://www.bankofamerica.com/',
      ig: 'bankofamerica',
      bitmoji: 'beard-job.jpg',
      type: 'large corporation',
      location: 'Charlotte, NC',
      time: '12/2000 - 08/2011',
      title: 'VP; Senior Change Manager',
      expanded: true,
      summary:
        'Technical Project Manager for Bank of Americas Mergers & Acquisitions Integration as well as Mortgage Business Initiatives. ',
      detail: [],
      logo: '/assets/img/bofa-logo.jpg',
      banner: '/assets/img/bofa-2.jpg',
      timeline: [
        {
          name: 'Teller',
          description: 'High school job 🤘',
        },
        {
          name: 'Senior Teller',
          description: 'Promoted 🎉',
        },
        {
          name: 'Customer Service Representative II',
          description: 'Greeter 🤝',
        },
        {
          name: 'Teller Coordinator',
          description: 'Responsible for opening/closing the banking center 😬',
        },
        {
          name: 'Teller Operations Specialist',
          description: 'Teach others 🤷‍♂️',
        },
        {
          name: 'Personal Banker',
          description: 'Post college job 👨‍🎓',
        },
        {
          name: 'Assistant Banking Center Manager',
          description: '1st job with direct reports 👨‍👧‍👦',
        },
        {
          name: 'Banking Center Manager II; Associate Vice President',
          description: 'Managed banking centers during the Financial Crisis 📉',
        },
        {
          name: 'Change Consultant; Associate Vice President',
          description:
            'Start of my career in Change Management. Worked as a consultant executing the LaSalle Bank, Countrywide, and Merrill Lynch mergers. 💸',
        },
        {
          name: 'Senior Change Consultant; Vice President',
          description:
            'Program Management role with a portfolio of Mortgage Technology projects in the combined BofA/Countrywide world 🌎',
        },
        {
          name: 'Senior Change Manager; Vice President',
          description:
            'Technical Program Management for big corporate change initiatives as well as the execution phase of Mergers & Acquisitions. I specialized in Retail Banking /Mortgage Technology projects and got trained in Six Sigma, SDLC, and Change Management. Worked closely with PMO and cross functionally across complex organization to deliver change for people, processes, and technology. 🏦',
        },
      ],
    },
    {
      company: 'Envida',
      website: 'https://envida.com',
      ig: 'envidagroup',
      bitmoji: '',
      type: 'agency',
      location: 'Scottsdale, AZ',
      time: '2015 - 2017',
      title: 'Web Designer',
      summary:
        'Web Designer and Digital Marketer for a boutique Digital Marketing agency specializes in local business and Multi-Family Residential companies.',
      detail: null,
      expanded: true,
      logo: '/assets/img/envida-logo.jpg',
      // banner: '/assets/img/envida.jpg',
      video: '/assets/img/envida.mp4',
    },
    {
      company: 'SocialWhirled',
      status: 'Dead',
      website: 'https://www.crunchbase.com/organization/socialwhirled',
      ig: 'socialwhirled',
      bitmoji: 'bit-work.jpg',
      type: 'startup',
      location: 'Phoenix, AZ',
      time: '2014',
      title: 'Director of Programs',
      expanded: true,
      summary:
        'Technical Lead for a SaaS startup that created Marketing Campaign Software for clients like Pepsi, Intel, and the New England Patriots.',
      detail: [
        'Work closely with CEO to determine product direction, roadmap, and next steps.',
        'Hired, mentor, and oversaw 10 person in-house Ruby on Rails dev team.',
        'Managed offshore India team of 7 Ruby on Rails developers.',
        'Implemented Kanban Methodology into the SDLC with Pivotal Tracker and Trello.',
        'Implemented Agile into the SDLC with daily stand-ups for both dev teams.',
        'Managed API Setup/Keys with Integration partners including Facebook, Google, Twitter, SMS Partner.',
        'Restructured AWS Instances with Dev Ops to save 40% in monthly AWS server costs.',
        'Managed Multi-Tenant SSL/TLS certificates to track expirations and avoid production outages.',
        'Sales engineer in sales demos with prospective clients.',
        'Technical point of contact for client security ops teams to ensure platform passes security and performance tests.',
        'Create job descriptions, source quality candidates, hire and mentor engineers.',
      ],
      logo: '/assets/img/socialwhirled.jpg',
      banner: '/assets/img/sw.JPG',
    },
    // {
    //   company: "Yo Moments",
    //   status: "Dead",
    //   website:
    //     "http://aztechbeat.com/2013/10/snap-app-composite-startup-yo-moments-creates-memories-through-your-phone/",
    //   ig: null,
    //   bitmoji: "beard-job.png",
    //   type: "startup",
    //   location: "San Diego, CA",
    //   time: "2013",
    //   title: "Co-Founder",
    //   summary:
    //     "Yo! Moments is a (failed) startup I was a part of in 2013. I attended a Startup Weekend in San Diego with the idea to re-invent the fraternity/sorority composite industry for groups/events. My team won Startup Weekend by building a web app (YoMo) that creates composite photos without the overhead of a professional photographer. After formation we were incubated at Desarollo inside InfusionSoft and at SkySong in ASU's Startup Accelerator Program. The company fell apart in early 2014.",
    //   detail: null,
    //   logo: "yo-logo.png"
    // },
    {
      company: 'Ryland Homes / Mortgage',
      status: 'Purchased by Lennar Homes',
      website: 'https://www.lennar.com/',
      ig: null,
      bitmoji: 'beard-job.png',
      type: 'large corporation',
      location: 'Phoenix, AZ',
      time: '2012',
      title: 'IT Project Manager',
      expanded: true,
      summary:
        'Helped a national home builder re-evaluate/digitize the technology & processes powering their mortgage company, Ryland Mortgage. I reported to the C.I.O. and worked closely with mortgage business leadership to determine needs, evaluate solutions, and select/customize a target Loan Origination System that fit the unique needs of a home builder.',
      detail: [
        'Worked closely with Ryland Mortgage leadership to determine current process GAPs and complete needs assessment for target system.',
        'Work with C.I.O. of Home Builder to determine technology needs for target system and contractural constraints. ',
        'Evaluated vendor loan system solutions for ideal needs of the home builder and coordinated demos with stakeholders.',
        'Selected Encompass 360 to be the target system & completed redline process with C.F.O. to ensure legal needs are met in Ellie Mae contract.',
        'Determine Implementation Plan and completed a successful handoff to implementation team at Ellie Mae.',
      ],
      logo: '/assets/img/ryland-logo.png',
      banner: '/assets/img/ryland.jpg',
    },
  ];

  constructor(titleService: Title) {
    titleService.setTitle('Shawn_Partridge_CV.html');
  }

  ngOnInit() {}

  ngAfterViewInit() {
    const element = document.getElementById('youtube-eqxplus');
    element.setAttribute('src', 'https://www.youtube.com/embed/53EeSd2sEgA');

    const element2 = document.getElementById('youtube-henrihome');
    element2.setAttribute('src', 'https://www.youtube.com/embed/qSvoSlx-_UE');
  }
}
