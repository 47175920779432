<div class="hire-wrapper">
  <h2>Get in Touch:</h2>
  <h3>Email: shawn at shawn.digital</h3>
  <div>
    <a
      class="typeform-share button"
      href="https://shawn18.typeform.com/to/Bqccop"
      data-mode="popup"
      data-auto-open="false"
      data-hide-headers="true"
      data-hide-footer="true"
      target="_blank"
    >
      <button mat-raised-button class="btn-grad-2 cta" color="primary">
        Send Message
      </button>
    </a>
  </div>
</div>
