import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {
  projects = [
    // {
    //   title: 'Billboard Tees',
    //   company: 'eCommerce Developer',
    //   website: 'https://billboardtees.fun',
    //   ig: 'billboard_teees',
    //   bitmoji: 'bit-long-dog.png',
    //   time: '2020',
    //   summary: 'A Human Scrabble eCommerce Project.',
    //   logo: '/assets/img/bt-a.gif',
    //   banner: 'https://billboardtees.fun/assets/images/header-logo.png',
    //   tech: [
    //     'nodejs',
    //     'angularjs',
    //     'typescript',
    //     'javascript',
    //     'firebase',
    //     'heroku',
    //     'git',
    //   ],
    // },
    {
      title: 'Technical Artist',
      summary:
        'Immerse yourself in the tranquil serenity of the Metaquarium, an innovative digital aquarium experience. As you navigate the spatial seas of the Ethereum Exhibit, you will encounter 8192 unique 3D fish NFTs. Their mesmerizing ballet and vivid colors breathe new life into the classic art of screensavers, reinventing them as transcendent aquarium experiences for the spatial age. Simply sit back, enjoy the vibes, and let the underwater pixels wash over you.',
      company: 'Metaquarium.xyz',
      logo: 'https://assets.website-files.com/6185799a60609e9339013f9e/64644196c961bced99a6cf00_Asset%2014logo.svg',
      website: 'https://eth.metaquarium.xyz',
      ig: 'metaquarium',
      bitmoji: 'bit-long-dog.png',
      time: '2023',
      banner: '/assets/img/metaquarium-vison2.png',
      tech: [
        'ethereum',
        'solidity',
        'webgl',
        'threejs',
        'nodejs',
        'angularjs',
        'typescript',
        'aws',
        'blender',
        'python',
        'pytorch',
      ],
    },
    {
      title: 'Developer',
      summary:
        'Freelance gig for a Direct Mail Marketing Platform for the real estate industry. Primarily worked on features related to User Experience and onboarding of beta clients.',
      company: 'Wise Pelican',
      logo: '/assets/img/wisepelican.png',
      website: 'https://wisepelican.com',
      ig: 'wisepelican',
      bitmoji: 'bit-long-dog.png',
      time: '2019-2020',
      banner: '/assets/img/wp-mock.png',
      tech: [
        'nodejs',
        'angularjs',
        'typescript',
        'javascript',
        'parse',
        'mongodb',
        'heroku',
        'git',
      ],
    },
    {
      title: 'Dog Star Search 2020',
      summary:
        'Annual open casting for the top dogs to make the 2020 Calendar.',
      company: 'Puppies Make Me Happy',
      logo: 'https://cdn.shopify.com/s/files/1/1093/8038/files/t-ball-80-2.gif?v=1566927003',
      website: 'https://puppiesmakemehappy.com/pages/star-search',
      ig: 'puppiesmakemehappy',
      bitmoji: 'bit-long-dog.png',
      time: 'Fall 2019',
      banner: '/assets/img/mock-stars.png',
      tech: [
        'shopify',
        'angularjs',
        'firebase',
        'zapier',
        'nodejs',
        'mailchimp',
      ],
    },
    {
      title: 'D2C Site Redesign',
      summary:
        'Theme Refresh and Redesign. Custom built features using the Shopify API and SDK to remove dependencies on 3rd party apps.',
      company: 'Puppies Make Me Happy',
      logo: 'https://cdn.shopify.com/s/files/1/1093/8038/files/t-ball-80-2.gif?v=1566927003',
      website: 'https://puppiesmakemehappy.com/',
      ig: 'puppiesmakemehappy',
      bitmoji: 'bit-long-dog.png',
      time: 'Summer 2019',
      banner: '/assets/img/pup-mock-2.png',
      tech: [
        'shopify',
        'jquery',
        'photoshop',
        'illustrator',
        'googleanalytics',
      ],
    },

    {
      title: 'Frontend Developer',
      summary:
        'Freelance gig for a specialty car club. I contributed mostly front-end features to a Ruby on Rails based application.',
      company: '3 Pedal Car Club',
      logo: '/assets/img/3p-a.gif',
      website: 'https://3pcca.com/',
      ig: '3pedalcarclub',
      bitmoji: 'bit-long-dog.png',
      time: 'Winter 2018',
      banner: '/assets/img/3p2.png',
      tech: ['rubyonrails', 'git'],
    },
    {
      title: 'Ionic App Developer',
      summary:
        'Freelance gig for a mediatation app. I helped finish and deliver the final features of a major version release.',
      company: 'S.O.S. Method Meditation App',
      logo: '/assets/img/sos.png',
      website: 'http://sosmethod.co/',
      ig: 'puppiesmakemehappy',
      bitmoji: 'bit-long-dog.png',
      time: 'Fall 2019',
      banner: '/assets/img/sos3.png',
      tech: ['angularjs', 'ionicjs', 'git'],
    },
    {
      title: 'Wonder Woman Snapchat AR Lens',
      summary: 'Made for the Snapchat Lens Studio Challenge.',
      company: 'Creator',
      logo: '/assets/img/ww.png',
      website:
        'https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=ABB10F7B510049A1B363A8ACAE100879&metadata=01',
      bitmoji: 'bit-long-dog.png',
      time: 'Fall 2018',
      // banner: '/assets/img/ww2.jpg'
      tech: ['illustrator', 'photoshop'],
    },
    {
      title: 'Your Dog Here Studio',
      summary:
        'Custom backend application to make it easy to put your dog on a lot of designs & garments. Integration with 3rd party providers.',
      company: 'Puppies Make Me Happy',
      logo: 'https://cdn.shopify.com/s/files/1/1093/8038/files/t-ball-80-2.gif?v=1566927003',
      website: 'https://puppiesmakemehappy.com/collections/your-dog-here',
      ig: 'puppiesmakemehappy',
      bitmoji: 'bit-long-dog.png',
      time: 'Summer 2018',
      banner: '/assets/img/mock-ydh.png',
      tech: [
        'angularjs',
        'zapier',
        'sendgrid',
        'nodejs',
        'shopify',
        'photoshop',
        'firebase',
      ],
    },
    {
      title: 'Puppies Vision Snapchat AR Lens',
      summary:
        'Experiential A/R Component to Puppy Vision Sunglasses. Try on with Snapchat.',
      company: 'Puppies Make Me Happy',
      logo: '/assets/img/pup-vision.png',
      website:
        'https://puppiesmakemehappy.com/products/puppy-vision-sunglasses?_pos=1&_sid=015af3f41&_ss=r',
      ig: 'puppiesmakemehappy',
      bitmoji: 'bit-long-dog.png',
      time: 'Fall 2018',
      // banner: '/assets/img/pup-vision.svg'
      tech: ['illustrator', 'photoshop'],
    },
    {
      title: 'B2B Site Design',
      summary: 'Migrate wholesale business to new customized Shopify Store',
      company: 'Puppies Make Me Happy',
      logo: 'https://cdn.shopify.com/s/files/1/1093/8038/files/t-ball-80-2.gif?v=1566927003',
      website: 'https://wholesale.puppiesmakemehappy.com',
      ig: 'puppiesmakemehappy',
      bitmoji: 'bit-long-dog.png',
      time: 'Fall 2018',
      banner: '/assets/img/pup-wholesale-mock-2.png',
      tech: [
        'shopify',
        'jquery',
        'photoshop',
        'illustrator',
        'googleanalytics',
      ],
    },
    {
      title: 'Puppies Sticker Pack for iOS, Instagram and Snapchat',
      summary: 'Built out Verified Giphy Channel for Instagram and Snap GIFs',
      company: 'Puppies Make Me Happy',
      logo: 'https://media.giphy.com/media/3ohs4z1EjGMQekU4Ss/200w_d.gif',
      website: 'https://giphy.com/puppiesmakemehappy',
      ig: 'puppiesmakemehappy',
      bitmoji: 'bit-long-dog.png',
      time: '2017',
      banner: '/assets/img/mock-giphy.png',
      tech: ['illustrator', 'photoshop'],
    },
    {
      title: 'Ionic Mobile App Developer',
      summary:
        'Property Management Software Application. Rails backend with Ionic mobile apps',
      company: 'Henri Home',
      logo: '/assets/img/henri-app-logo.png',
      website: 'https://henrihome.com/',
      bitmoji: 'bit-long-dog.png',
      time: '2017',
      banner: '/assets/img/henri-ios.png',
      tech: ['angularjs', 'ionicjs', 'git'],
    },
    // {
    //   title: 'D2C Website Relaunch',
    //   summary: 'Shopify site customizations.',
    //   company: 'Puppies Make Me Happy',
    //   logo:
    //     'https://cdn.shopify.com/s/files/1/1093/8038/files/t-ball-80-2.gif?v=1566927003',
    //   website: 'https://puppiesmakemehappy.com',
    //   ig: 'puppiesmakemehappy',
    //   bitmoji: 'bit-long-dog.png',
    //   time: '2017',
    //   banner: 'https://media.giphy.com/media/3o85xBMHmEuuSuCNwc/giphy.gif'
    // },
    {
      title: 'Agency Site Design',
      summary: 'Redesign for Digital Marketing Agency.',
      company: 'Envida',
      logo: '/assets/img/envida-logo.jpg',
      website: 'https://envidagroup.com',
      ig: 'envidagroup',
      bitmoji: 'bit-long-dog.png',
      time: 'Fall 2018',
      banner: '/assets/img/mock-envida.png',
      tech: ['angularjs', 'php', 'photoshop'],
    },
    {
      title: 'Design Studio Site Design',
      summary: 'Marketing Site for an Audio Acoustics Design Studio.',
      company: 'Callahan Studios',
      logo: '/assets/img/callahan-1.gif',
      website: 'https://callahanstudios.com',
      ig: '',
      bitmoji: 'bit-long-dog.png',
      time: '2015',
      banner: '/assets/img/call-mock-2.png',
      tech: [
        'wordpress',
        'php',
        'photoshop',
        'jquery',
        'mysql',
        'googleanalytics',
      ],
    },
    {
      title: 'Web Producer',
      summary:
        'Technical Writing and Digital Marketing for Web Series. Wordpress. IMDB. Wikipedia.',
      company: 'Talking Marriage with Ryan Bailey',
      logo: '/assets/img/ryan.png',
      website: 'https://talkingmarriage.com',
      ig: 'ryanbailey25',
      bitmoji: 'bit-long-dog.png',
      time: '2014-2016',
      banner: '/assets/img/tm-mock-3.png',
      imdb: 'https://www.imdb.com/title/tt3584130/',
      tech: [
        'wordpress',
        'php',
        'photoshop',
        'jquery',
        'mysql',
        'googleanalytics',
      ],
    },
  ];

  constructor(titleService: Title) {
    titleService.setTitle('Shawn_Partridge/Projects.html');
  }

  ngOnInit() {}
}
