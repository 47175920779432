<div class="projects-wrapper">
  <h2>Recent Digital Projects:</h2>
  <div *ngIf="!projects" class="loading">
    <img src="https://media.giphy.com/media/QvS3lFaN6i07aTNdoC/giphy.gif" />
  </div>
  <mat-card class="job" *ngFor="let job of projects">
    <mat-card-header class="job-header">
      <img
        *ngIf="job.logo"
        mat-card-avatar
        class="brand-logo"
        src="{{ job.logo }}"
      />
      <img
        *ngIf="!job.logo"
        mat-card-avatar
        class="brand-logo"
        src="'https://cdn.shopify.com/s/files/1/1093/8038/files/t-ball-80-2.gif?v=1566927003'"
      />
      <mat-card-title>{{ job.title }}</mat-card-title>
      <mat-card-subtitle
        ><a
          class="co-link"
          href="{{ job.website }}"
          target="_blank"
          alt="{{ job.company }}"
          >{{ job.company }}</a
        >
        <div class="job-time">{{ job.time }}</div></mat-card-subtitle
      >
      <div class="link-icon">
        <a
          *ngIf="job.imdb"
          class="co-link imdb"
          href="{{ job.imdb }}"
          target="_blank"
          alt="IMDB"
          ><i class="fab fa-imdb"></i
        ></a>
        <a
          *ngIf="job.website"
          class="co-link"
          href="{{ job.website }}"
          target="_blank"
          alt="{{ job.company }}"
          ><i class="fa fa-link"></i
        ></a>
      </div>
    </mat-card-header>
    <img
      *ngIf="job.banner"
      src="{{ job.banner }}"
      alt="{{ job.company }}"
      class="banner"
    />
    <p class="job-description">
      {{ job.summary }}
    </p>
    <!-- <ul *ngIf="job.detail">
      <li *ngFor="let detail of job.detail">{{ detail }}</li>
    </ul> -->
    <h4>Technology Used</h4>
    <ul class="tech-stack">
      <li *ngFor="let item of job.tech">
        <span class="tech-name">{{ item }}</span>
      </li>
    </ul>
  </mat-card>

  <h2>Other Domains I've Contributed to:</h2>
  <mat-card class="job">
    <mat-list class="listbox">
      <mat-list-item>markowens.com</mat-list-item>
      <mat-list-item>llsaz.com</mat-list-item>
      <mat-list-item>pymk.tv</mat-list-item>
      <mat-list-item>dierkswhiskeyrow.com</mat-list-item>
      <mat-list-item>elhefe.com</mat-list-item>
      <mat-list-item>storecapital.com</mat-list-item>
      <mat-list-item>admireplasticsurgery.com</mat-list-item>
      <mat-list-item>yomoments.com</mat-list-item>
      <mat-list-item>mroldtown.com</mat-list-item>
      <mat-list-item>asupikes.com</mat-list-item>
      <mat-list-item>rkdabrewing.com</mat-list-item>
    </mat-list>
  </mat-card>
  <h2>Wedding Websites I've made:</h2>
  <mat-card class="job">
    <mat-list class="listbox">
      <mat-list-item>stoveandb.com</mat-list-item>
      <mat-list-item>travisandjoriensayido.com</mat-list-item>
      <mat-list-item>crystalandgarret.com</mat-list-item>
    </mat-list>
  </mat-card>
  <!-- <h2>
    <a href="https://abc.go.com/shows/the-bachelor">Bachelor</a> Audition Tapes
    Produced: 1
  </h2> -->
</div>
