<div class="cv-wrapper">
  <h2>Places I've Worked:</h2>
  <mat-card class="job" *ngFor="let job of experience">
    <mat-card-header class="job-header">
      <img mat-card-avatar class="brand-logo" src="{{ job.logo }}" />
      <mat-card-title>{{ job.title }}</mat-card-title>
      <mat-card-subtitle
        ><a
          class="co-link"
          href="{{ job.website }}"
          target="_blank"
          alt="{{ job.company }}"
          >{{ job.company }}</a
        >
        <div class="job-time">{{ job.time }}</div></mat-card-subtitle
      >
    </mat-card-header>
    <img
      *ngIf="job.banner"
      src="{{ job.banner }}"
      alt="{{ job.company }}"
      class="banner"
    />
    <video *ngIf="job.video" controls class="video" loop>
      <source src="{{ job.video }}" type="video/mp4" />
    </video>
    <div *ngIf="job.youtube">
      <iframe
        id="youtube-{{ job.ig }}"
        frameborder="0"
        width="100%"
        class="youtube"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <p class="job-description">
      {{ job.summary }}
    </p>
    <div *ngIf="job.detailSummary">{{ job.detailSummary }}</div>
    <ul *ngIf="job.detail && job.expanded">
      <li *ngFor="let detail of job.detail">{{ detail }}</li>
    </ul>
    <div *ngIf="job.timeline" class="timeline">
      <mat-vertical-stepper linear="false">
        <ng-template matStepperIcon="edit" let-index="index"></ng-template>
        <mat-step *ngFor="let step of job.timeline" matStepperIcon="edit">
          <ng-template matStepLabel>{{ step.name }}</ng-template>
          <p class="timeline-desc">
            {{ step.description }}
          </p>
        </mat-step>
      </mat-vertical-stepper>
    </div>
  </mat-card>
  <!-- <h2>Jobs that excite me:</h2> -->
</div>
<!-- <marquee
  >Typical Roles: Technical Program Manager. Full Stack Developer. Digital
  Designer. Technology Lead. Change Management. Growth Hacker. IT Project
  Manager.
</marquee> -->
